@import 'https://use.typekit.net/qat3xuf.css';
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600;700&family=Poppins:wght@700&display=swap');

/** @format */
*::before,
*,
*::after {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
}

:root {
  text-rendering: optimizeLegibility;
  text-size-adjust: 100%;
}

body {
  font-family: 'Open Sans', 'Roboto', 'futura-pt', 'Nunito';
  font-size: $baseFontSize;
  line-height: $baseLineHeight;
  color: #414141;
}

h1 {
  $fontSize: $scaleFactor * $scaleFactor * $scaleFactor;

  font-family: 'futura-pt', 'Nunito';
  font-size: $fontSize * $baseFontSize;
  font-weight: 600;
  letter-spacing: -0.4px;
  line-height: calc(1.5 * $baseLineHeight / $fontSize);
  margin: (2 * $baseFontSize * $baseLineHeight) 0;
  color: $black;
  text-transform: uppercase;
}

h2 {
  $fontSize: $scaleFactor * $scaleFactor;

  font-family: 'futura-pt', 'Nunito';
  font-size: 32px;
  font-weight: 600;
  letter-spacing: -0.2px;
  line-height: calc(1.5 * $baseLineHeight / $fontSize);
  margin: (2 * $baseFontSize * $baseLineHeight) 0 ($baseFontSize * $baseLineHeight);
  color: $black;
  text-transform: uppercase;
}

h3 {
  $fontSize: $scaleFactor;

  font-family: 'futura-pt', 'Nunito';
  font-size: $fontSize * $baseFontSize;
  font-weight: 500;
  letter-spacing: -0.1px;
  line-height: 1 * $baseLineHeight / $fontSize;
  margin: (2 * $baseFontSize * $baseLineHeight) 0 ($baseFontSize * $baseLineHeight);
  color: $black;
  text-transform: uppercase;
}

h4 {
  font-family: 'futura-pt', 'Nunito';
  font-size: $baseFontSize;
  font-weight: 400;
  line-height: $baseLineHeight;
  margin: ($baseFontSize * $baseLineHeight) 0 0;
  color: $black;
  text-transform: uppercase;
}

h4 + * {
  margin-top: 0 !important;
}

h5 {
  font-family: 'futura-pt', 'Nunito';
  font-size: 0.75 * $baseFontSize;
  letter-spacing: 0.2px;
  line-height: 0.75 * $baseLineHeight;
  margin: ($baseFontSize * $baseLineHeight) 0 0;
  text-transform: uppercase;
}

p {
  font-family: 'Open Sans', 'Roboto', 'futura-pt', 'Nunito';
  font-size: $baseFontSize;
  line-height: $baseLineHeight;
  color: #414141;
  margin: ($baseFontSize * $baseLineHeight) 0;
}

ul,
ol {
  font-family: 'Open Sans', 'Roboto', 'futura-pt', 'Nunito';
  font-size: $baseFontSize;
  line-height: $baseLineHeight;
  color: #414141;
  margin: ($baseFontSize * $baseLineHeight) 0;
  padding-left: 0.5 * $baseFontSize * $baseLineHeight;
}

// li + li {
//     margin-top: 0.25 * $baseFontSize * $baseLineHeight;
// }

dl {
  font-family: 'Open Sans', 'Roboto', 'futura-pt', 'Nunito';
  font-size: $baseFontSize;
  line-height: $baseLineHeight;
  color: #414141;
  margin: ($baseFontSize * $baseLineHeight) 0;
  border-top: 2px solid;
  border-bottom: 2px solid;
}

dt,
dd {
  font-family: 'Open Sans', 'Roboto', 'futura-pt', 'Nunito';
  font-size: $baseFontSize;
  line-height: $baseLineHeight;
  color: #414141;
  margin: 0;
}

dt {
  font-family: 'Open Sans', 'Roboto', 'futura-pt', 'Nunito';
  font-size: $baseFontSize;
  line-height: $baseLineHeight;
  color: #414141;

  border-top: 1px solid;
  font-weight: bold;
  padding: 0.5 * $baseFontSize * $baseLineHeight;

  &:first-of-type {
    border-top: 0;
  }
}

dd {
  font-family: 'Open Sans', 'Roboto', 'futura-pt', 'Nunito';
  font-size: $baseFontSize;
  line-height: $baseLineHeight;
  color: #414141;

  padding: 0 0.5 * $baseFontSize * $baseLineHeight 0.5 * $baseFontSize * $baseLineHeight;
}

small {
  font-family: 'Open Sans', 'Roboto', 'futura-pt', 'Nunito';
  line-height: $baseLineHeight;
  color: #414141;

  font-size: 75%;
}

blockquote {
  font-size: 125%;
  margin: ($baseFontSize * $baseLineHeight) 0;
  text-align: center;

  :first-child::before {
    content: open-quote;
  }

  :last-child::after {
    content: close-quote;
  }
}

@media (min-width: 40em) {
  $scaleFactor: 1.5;

  body {
    $fontSize: $scaleFactor * $scaleFactor * $scaleFactor;

    font-size: $fontSize;
    line-height: $baseLineHeight;
    color: #414141;
  }

  #pickup-point-selection h1 {
    $fontSize: $scaleFactor * $scaleFactor * $scaleFactor;

    font-size: $fontSize * $baseFontSize;
    letter-spacing: -1px;
    line-height: 2.5 * $baseLineHeight / $fontSize;
    margin: (3 * $baseFontSize * $baseLineHeight) 0;
  }

  #pickup-point-selection h2 {
    $fontSize: $scaleFactor * $scaleFactor;

    font-size: $fontSize * $baseFontSize;
    letter-spacing: -0.5px;
    line-height: 1.75 * $baseLineHeight / $fontSize;
    margin: (3 * $baseFontSize * $baseLineHeight) 0 (2 * $baseFontSize * $baseLineHeight);
  }

  #pickup-point-selection h3 {
    $fontSize: $scaleFactor;

    font-size: $fontSize * $baseFontSize;
    letter-spacing: -0.25px;
    line-height: 1.25 * $baseLineHeight / $fontSize;
  }

  #pickup-point-selection dl {
    display: grid;
    grid-template-columns: max-content auto;
  }

  #pickup-point-selection dt,
  #pickup-point-selection dd {
    border-top: 1px solid;
    padding: ($baseFontSize * $baseLineHeight);

    &:first-of-type {
      border-top: 0;
    }
  }

  #pickup-point-selection blockquote {
    font-size: 150%;
  }
}
