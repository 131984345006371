@import './base/breakpoints.scss';
@import './base/plugins.scss';

@import './base/variables.scss';
@import './base/colors.scss';
@import './base/typography.scss';
@import './base/document.scss';

@import './base/icons';
@import './base/tooltip';

@import './base/forms';
@import './base/buttons';
@import './base/links';
@import './base/heading';

.loading {
  will-change: transform;
  animation: placeHolderShimmer 1.5s linear infinite forwards;
  -webkit-backface-visibility: hidden;
  background: #e6e6e6;
  background: linear-gradient(90deg, #eee 8%, #ddd 18%, #eee 33%);
  background-size: 800px 104px;
  height: 100%;
  position: relative;
}

@keyframes placeHolderShimmer {
  0% {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    background-position: -468px 0;
  }
  to {
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    background-position: 468px 0;
  }
}

.mat-dialog-container {
  background-color: none;
  box-shadow: none;
}

.mat-dialog-container {
  background-color: none !important;
  box-shadow: none !important;
  background: none !important;
  padding: 0px !important;
}

.cdk-overlay-pane.enter-waybill__dialog {
  width: 100% !important;
  max-width: 100% !important;
  height: 100% !important;

  @include min-tablet {
    width: 50% !important;
    height: auto !important;
  }
}
