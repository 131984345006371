@import '_colors';

.form {
  &.submitted {
    .input.ng-pristine.ng-invalid {
      border: 1px solid $error;
      color: $error;
    }
  }
}


.input {
  padding: $buildingUnit;
  border: 1px solid $grey;
  border-radius: $buildingUnit / 4;
  width: 100%;
  margin: 0;

  font-family: 'Open Sans';
  font-size: $baseFontSize;
  line-height: $baseLineHeight;
  color: #414141;

  &.ng-invalid.ng-touched {
    border: 1px solid $error;
    color: $error;
  }

  &:focus {
    outline: none;
    border: 1px solid $darkYellow;
  }
}

.input__text {
  @extend .input;
}

.input__text + .input__error {
  margin-bottom: $buildingUnit * 2;
}

.input__error {
  font-size: $baseFontSize * 0.8;
  line-height: $baseLineHeight;
  color: $errorFont;
  background-color: $errorBackground;
  padding: $buildingUnit / 3 $buildingUnit;
  border-radius: $buildingUnit / 4;
}

.select {
  @extend .input;
  height: $buildingUnit * 4;
}

label {
  font-family: 'Roboto', 'Helvetica' !important;
  font-size: $baseFontSize * 0.9;
  line-height: $baseLineHeight;

  display: flex;
  flex-direction: column;

  font-weight: 700;
}

.form-group {
  position: relative;

  input[type='search']::-webkit-search-decoration,
  input[type='search']::-webkit-search-cancel-button,
  input[type='search']::-webkit-search-results-button,
  input[type='search']::-webkit-search-results-decoration {
    display: none;
  }

  input:read-only {
    background-color: #fafafa;
    color: $grey;
  }

  label {
    position: absolute;
    font-family: 'Open Sans' !important;
    font-size: 16px !important;
    font-weight: 400;
    transition: all ease-in-out 0.2s;
    pointer-events: none;
  }

  input,
  textarea,
  select {
    border: solid 1px #ccc;
    font-family: 'Open Sans';
    font-size: 16px;
    padding: 13px 16px;
    padding-right: 50px;
    border-radius: 5px;
    width: 100%;
    max-width: 400px;
    display: block;
    transition: all ease-in-out 0.2s;
    margin-bottom: 30px !important;
  }

  select {
    cursor: pointer;
    background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    linear-gradient(to right, #cccccc44, #cccccc44);
    background-position: calc(100% - 20px) 22px, calc(100% - 15px) 22px, 100% 0;
    background-size: 5px 5px, 5px 5px, 43px 100%;
    background-repeat: no-repeat;

    -webkit-appearance: none;
    -moz-appearance: none;
  }

  input:before,
  textarea:before,
  select:before {
    box-sizing: border-box;
    display: block;
  }

  input:focus,
  textarea:focus,
  select:focus {
    outline: none;
    border-color: #000;
  }

  input:invalid:not(:placeholder-shown),
  textarea:invalid:not(:placeholder-shown),
  select:invalid.dirty,
  input.invalid,
  textarea.invalid,
  select.invalid.dirty {
    border-color: #ce0000;
  }

  input:invalid:not(:placeholder-shown) ~ label,
  textarea:invalid:not(:placeholder-shown) label,
  select:invalid.dirty ~ label,
  input.invalid ~ label,
  textarea.invalid ~ label,
  select.invalid ~ label {
    color: #ce0000 !important;
  }

  input ~ label,
  textarea ~ label,
  select ~ label {
    top: 16px;
    left: 16px;
  }

  input:focus ~ label,
  textarea:focus ~ label,
  select:focus ~ label,
  input:not(:placeholder-shown) ~ label,
  textarea:not(:placeholder-shown) ~ label,
  select.dirty ~ label,
  select.ng-valid ~ label {
    top: -14px;
    left: 5px;
    font-family: Poppins !important;
    text-transform: uppercase;
    font-size: 14px !important;
    background-color: #fff;
    padding: 0 10px;
    color: #414141;
  }

  i.search-icon {
    background-image: url('/assets/images/icons/Search.svg');
    background-size: cover;
    width: 24px;
    height: 24px;
    display: inline-block;
    position: absolute;
    top: 13px;
    right: 20px;
    filter: brightness(50%);
  }
}
