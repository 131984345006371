/** @format */

*::before,
*,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  height: 100%;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
}
