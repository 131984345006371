.tooltip {
  position: absolute;
  top: 13px;
  right: 2%;
  display: inline-block;
  background-image: url("/assets/images/icons/Help.svg");
  background-size: cover;
  width: 24px;
  height: 24px;
  cursor: pointer;

  @media (min-width: 27em) {
    left: 365px;
  }
}

.tooltip::before,
.tooltip::after {
  position: absolute;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s ease-in-out;
}

.tooltip:hover::before,
.tooltip:hover::after {
  opacity: 1;
  visibility: visible;
}

.tooltip::before {
  content: attr(data-tooltip);
  z-index: 2;
  width: 210px;
  color: #fff;
  text-align: center;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 5px;
  padding: 5px;
}

.tooltip::after {
  content: "";
  width: 0;
  height: 0;
}

.tooltip--top::before,
.tooltip--top::after {
  bottom: 100%;
  left: 50%;
  transform: translate(-50%);
  margin-bottom: 15px;
}

.tooltip--top::after {
  margin-bottom: 8px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-top: 7px solid rgba(0, 0, 0, 0.7);
}

.tooltip--bottom::before,
.tooltip--bottom::after {
  top: 100%;
  left: 50%;
  transform: translate(-50%);
  margin-top: 15px;
}

.tooltip--bottom::after {
  margin-top: 8px;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 7px solid rgba(0, 0, 0, 0.7);
}

.tooltip--right::before,
.tooltip--right::after {
  top: 50%;
  left: 100%;
  transform: translate(0, -50%);
  margin-left: 15px;
}

.tooltip--right::after {
  margin-left: 8px;
  border-top: 5px solid transparent;
  border-right: 7px solid rgba(0, 0, 0, 0.7);
  border-bottom: 5px solid transparent;
}

.tooltip--left::before,
.tooltip--left::after {
  top: 50%;
  right: 100%;
  transform: translate(0, -50%);
  margin-right: 15px;
}

.tooltip--left::after {
  margin-right: 8px;
  border-top: 5px solid transparent;
  border-left: 7px solid rgba(0, 0, 0, 0.7);
  border-bottom: 5px solid transparent;
}
