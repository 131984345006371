.button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: $buildingUnit;
  text-transform: uppercase;

  border-radius: $buildingUnit / 4;
  border: 1px solid $yellow;
  color: $black;
  margin: $buildingUnit 0;
  background-color: $yellow;

  font-family: 'futura-pt', 'Nunito';
  font-weight: 700;
  font-size: $baseFontSize * 0.8;
  line-height: $baseLineHeight;

  outline: none;

  box-shadow: -2px 4px 5px -5px rgba(0, 0, 0, 0.21);

  .icon {
    margin: 0 $buildingUnit 0 0;
  }

  &:hover {
    cursor: pointer;
  }

  @include min-tablet {
    padding: $buildingUnit $buildingUnit * 2;
  }

  &:disabled {
    color: $disabled;
    background-color: $disabledBackground;
    cursor: not-allowed;
  }
}

.button {
  &--secondary {
    background-color: black;
    border: 1px solid $black;
    color: $white;
  }

  &--plain {
    box-shadow: none;
  }

  &--disabled,
  &:disabled {
    color: $disabled;
    background-color: $disabledBackground;
    cursor: not-allowed;
    border: none;
  }
}

button {
  @extend .button;
}

button.loading {
  background-color: transparent;
  border: 1px solid $black;
  color: $black;

  .icon {
    animation: spin 1000ms infinite linear;
  }
}

@keyframes fadeIt {
  0% {
    background-color: $yellow;
  }

  100% {
    background-color: transparent;
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(-359deg);
    transform: rotate(-359deg);
  }
}
