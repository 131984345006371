.heading {
  color: $black;
  text-transform: uppercase;
  font-weight: 700;
}

.heading__lined {
  font-size: 18px;
  display: flex;
  align-items: center;
  position: relative;
  padding-right: $buildingUnit;
  z-index: 5;
  margin: $buildingUnit * 2 0;

  @include min-tablet {
    margin: $buildingUnit * 4 0;
  }

  &::after {
    content: '';
    position: absolute;
    border: 1px solid $black;
    width: 100%;
    left: 0;
    z-index: -1;
  }

  > span {
    background: $white;
    padding-right: $buildingUnit;
  }
}
